<template>

  <div class="row" ref="sectionForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Name"
                                name="name"
                                @keyup="generateSomeFields"
                                fou
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
              </div>

              <div class="col-md-12">
                    <ValidationProvider
                      vid="title"
                      rules="required"
                      name="The Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Title"
                                name="title"
                                fou
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
              </div>

              <div class="col-md-12">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="URL"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
              </div>

              <div class="col-md-12">
                    <ValidationProvider
                      vid="description"
                      rules="required"
                      name="The Description"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Description"
                                name="description"
                                fou
                                v-model="formData.description">
                      </fg-input>
                    </ValidationProvider>
              </div>

              
              <div class="col-md-12">
                    <ValidationProvider
                      vid="second_title"
                      rules="required"
                      name="The Second Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Second Title"
                                name="second_title"
                                fou
                                v-model="formData.second_title">
                      </fg-input>
                    </ValidationProvider>
              </div>

              <div class="col-md-12">
                    <ValidationProvider
                      vid="second_description"
                      rules="required"
                      name="The Second Description"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Second Description"
                                name="second_description"
                                fou
                                v-model="formData.second_description">
                      </fg-input>
                    </ValidationProvider>
              </div>

              <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right">
                    <div slot="content">(290 × 390)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>

              <div class="col-12">
                <label>Sub Services</label>
                    <template class="row" v-for="(listItem,listItemIndex) in formData.sub_services">
                      <div class="row team_list_items">
                        <label>Sub Services # {{ listItemIndex + 1 }}</label>
                        <!--                list item title-->
                        <div class="col-md-12">
                          <ValidationProvider
                            vid="sub_services_name"
                            rules="required"
                            name="The Name"
                            v-slot="{ passed, failed,errors }">
                            <fg-input type="text"
                                      :key="'name_'+listItemIndex"
                                      :error="failed ? errors[0]: null"
                                      :label="'Name '+(listItemIndex+1)"
                                      v-model="formData.sub_services[listItemIndex].name">
                            </fg-input>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <ValidationProvider
                            vid="sub_services_title"
                            rules="required"
                            name="The Title"
                            v-slot="{ passed, failed,errors }">
                            <fg-input type="text"
                                      :key="'title_'+listItemIndex"
                                      :error="failed ? errors[0]: null"
                                      :label="'Title '+(listItemIndex+1)"
                                      v-model="formData.sub_services[listItemIndex].title">
                            </fg-input>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <label>Description # {{ listItemIndex+1 }}</label>
                          <ValidationProvider
                            vid="sub_services_description"
                            rules="required"
                            name="The Description"
                            v-slot="{ passed, failed,errors }">
                              <editor
                                v-model="formData.sub_services[listItemIndex].description"
                                :api-key="editorKey"
                                :init='editorConfig'
                                
                                :error="failed ? errors[0]: null"
                                :key="'description'+listItemIndex"
                              />
                        </ValidationProvider>
                        </div>
                        <div class="col-md-12">
                          <label>Image</label>
                          <el-tooltip placement="right">
                            <div slot="content">(290 × 390)</div>
                            <span style="margin: 0 5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-question-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path
                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                            </svg>
                            </span>
                          </el-tooltip>
                          <prime-uploader
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            :elementNum="1"
                            v-model="formData.sub_services[listItemIndex].image"
                          >
                          </prime-uploader>
                        </div>
                        <div class="col-md-12">
                          <label>Icon</label>
                          <el-tooltip placement="right">
                            <div slot="content">(290 × 390)</div>
                            <span style="margin: 0 5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-question-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path
                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                            </svg>
                            </span>
                          </el-tooltip>
                          <prime-uploader
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            :elementNum="1"
                            v-model="formData.sub_services[listItemIndex].icon"
                          >
                          </prime-uploader>
                        </div>
                      </div>
                      <div class="col-sm-1 align-self-center mt-3">
                        <l-button
                          @click="removeItem(formData.sub_services, listItemIndex)"
                          type="danger" size="sm">
                          <i class="fa fa-trash"></i>
                        </l-button>
                      </div>
                    </template>
                    <l-button
                      class="mt-3"
                      type="success"
                      @click="addNewItem(formData.sub_services, { name: '', description: '', title : '',image: '', icon : ''})"
                      size="sm">
                      <i class="fa fa-plus"></i>
                    </l-button>
              </div>


              <div class="col-12">
                <label>Software Integrations</label>
                    <template class="row" v-for="(listItem,listItemIndex) in formData.software_integrations">
                      <div class="row team_list_items">
                        <label>Software Integrations # {{ listItemIndex + 1 }}</label>
                        <!--                list item title-->
                        <div class="col-md-12">
                          <ValidationProvider
                            vid="software_integrations_name"
                            rules="required"
                            name="The Name"
                            v-slot="{ passed, failed,errors }">
                            <fg-input type="text"
                                      :key="'name_'+listItemIndex"
                                      :error="failed ? errors[0]: null"
                                      :label="'Name '+(listItemIndex+1)"
                                      v-model="formData.software_integrations[listItemIndex].name">
                            </fg-input>
                          </ValidationProvider>
                        </div>

                        <div class="col-md-12">
                          <label>Icon</label>
                          <el-tooltip placement="right">
                            <div slot="content">(290 × 390)</div>
                            <span style="margin: 0 5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-question-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path
                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                            </svg>
                            </span>
                          </el-tooltip>
                          <prime-uploader
                            :preview-width="'200px'"
                            :preview-height="'200px'"
                            :elementNum="1"
                            v-model="formData.software_integrations[listItemIndex].icon"
                          >
                          </prime-uploader>
                        </div>
                      </div>
                      <div class="col-sm-1 align-self-center mt-3">
                        <l-button
                          @click="removeItem(formData.software_integrations, listItemIndex)"
                          type="danger" size="sm">
                          <i class="fa fa-trash"></i>
                        </l-button>
                      </div>
                    </template>
                    <l-button
                      class="mt-3"
                      type="success"
                      @click="addNewItem(formData.software_integrations, { name: '', icon : ''})"
                      size="sm">
                      <i class="fa fa-plus"></i>
                    </l-button>
              </div>

              <!-- </div> -->
              <div class="col-12">
              <div class="form-group pt-4">
                <label>Is Active</label>&nbsp;
                <l-switch v-model="formData.is_active">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group pt-4">
                <label>Is Featured</label>&nbsp;
                <l-switch v-model="formData.is_featured">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/services/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import draggable from 'vuedraggable';

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    'editor': Editor,
    draggable
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formData: {
        name: "",
        title: "",
        description: "",
        second_title: "",
        second_description: "",
        image: "",
        url: '',
        sub_services: [],
        software_integrations: [],
        is_active: true,
        is_featured: true
      },
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sectionForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Service";
      this.getItem();
    } else {
      this.editMode = false;
      this.formTitle = "Add Service";
      this.loader.hide();
    }

  },


  methods: {
    getItem() {
      let data = {
        id: this.id,
      }
      this.axios.post("services/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Service Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("services/update/" + this.id, this.formData);
        successMessage = "Service updated successfully";
      } else {
        request = this.axios.post("services/create", this.formData);
        successMessage = "Service added successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/services/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems, initObject) {
      console.log(listItems)
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },
    generateSomeFields() {
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "-");
        }

        this.formData.url = newUrl;
      },
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
